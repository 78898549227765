import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import VendorDashboard from './VendorDashboard';
import LiveEvent from './LiveEvent';
import VendorPopup from './components/VendorPopup';
import { getWooCommerceProducts, getWooCommerceVendorProducts, getDokanUsers } from './api';

function App() {
  const [products, setProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorProducts, setVendorProducts] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const wooProducts = await getWooCommerceProducts();
        const dokanVendors = await getDokanUsers();
        setProducts(wooProducts);
        setVendors(dokanVendors);
      } catch (error) {
        console.error("Erreur lors de la r�cup�ration des donn�es:", error);
      }
    }
    fetchData();
  }, []);

  const fetchVendorProducts = async (vendorId) => {
    try {
      const products = await getWooCommerceVendorProducts(vendorId);
      setVendorProducts(products);
    } catch (error) {
      console.error(`Erreur lors de la r�cup�ration des produits pour le vendeur ${vendorId}:`, error);
    }
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard products={products} vendors={vendors} openPopup={openPopup} />} />
        <Route path="/vendor_dashboard" element={<VendorDashboard fetchVendorProducts={fetchVendorProducts} vendorProducts={vendorProducts} openPopup={openPopup} />} />
        <Route path="/live/:eventId" element={<LiveEvent />} />
      </Routes>
      {isPopupOpen && <VendorPopup closePopup={closePopup} />}
    </Router>
  );
}

export default App;