import axios from 'axios';
import config from './config';

// Configure axios pour envoyer les cookies de session avec chaque requ�te
axios.defaults.withCredentials = true;

// Fonction pour obtenir les produits de WooCommerce
export async function getWooCommerceProducts() {
  try {
    console.log('Fetching WooCommerce products...');
    const response = await axios.get(`${config.wooCommerce.url}products`, {
      auth: {
        username: config.wooCommerce.consumerKey,
        password: config.wooCommerce.consumerSecret
      }
    });
    console.log('WooCommerce products fetched:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des produits WooCommerce:', error.message);
    throw error; // Relancer l'erreur pour gestion ult�rieure
  }
}

// Fonction pour obtenir les produits d'un vendeur sp�cifique
export async function getWooCommerceVendorProducts(vendorId) {
  try {
    console.log(`Fetching WooCommerce products for vendor ${vendorId}...`);
    const response = await axios.get(`${config.wooCommerce.url}products`, {
      auth: {
        username: config.wooCommerce.consumerKey,
        password: config.wooCommerce.consumerSecret
      },
      params: {
        author: vendorId // Filtrer les produits en fonction de l'ID du vendeur
      }
    });
    console.log(`WooCommerce products for vendor ${vendorId} fetched:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Erreur lors de la r�cup�ration des produits du vendeur ${vendorId} :`, error.message);
    throw error;
  }
}

// Fonction pour obtenir les utilisateurs Dokan
export async function getDokanUsers() {
  try {
    console.log('Fetching Dokan vendors...');
    const response = await axios.get(`${config.dokan.url}stores`);
    console.log('Dokan vendors fetched:', response.data); // Ajoutez ce log pour v�rifier la r�ponse de l'API

    // Supprimer le filtre
    return response.data; // Retourner tous les vendeurs
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des vendeurs Dokan:', error.message);
    throw error;
  }
}

// Fonction pour obtenir les notifications
export async function getNotifications(token) {
  try {
    console.log('Fetching notifications...');
    const response = await axios.get(`${config.apiBaseUrl}/notifications`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Notifications fetched:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des notifications:', error.message);
    throw error;
  }
}

// Fonction pour obtenir les notifications d'un vendeur sp�cifique
export async function getVendorNotifications(token) {
  try {
    console.log('Fetching vendor notifications...');
    const response = await axios.get(`${config.apiBaseUrl}/vendor/notifications`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    console.log('Vendor notifications fetched:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des notifications du vendeur:', error.message);
    throw error;
  }
}

// Fonction pour marquer une notification comme lue
export async function markNotificationAsRead(notificationId) {
  try {
    console.log(`Marking notification ${notificationId} as read...`);
    const response = await axios.post(`${config.apiBaseUrl}/vendor/notifications/${notificationId}/read`);
    console.log('Notification marked as read:', response.data);
  } catch (error) {
    console.error('Erreur lors de la mise � jour de la notification:', error.message);
    throw error;
  }
}

// Fonction pour r�pondre � une invitation de Live Event
export const respondToLiveEvent = async (token, eventId, response) => {
  try {
    console.log(`respondToLiveEvent called with eventId: ${eventId} and response: ${response}`);
    const url = `${config.apiBaseUrl}/live-events/${eventId}/response`;
    const res = await axios.post(url, { response }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    console.log('Response sent:', res.data);
  } catch (error) {
    console.error('Erreur lors de l\'envoi de la r�ponse:', error.message);
    throw error;
  }
};