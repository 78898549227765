import React, { useState, useEffect } from 'react';
import './VendorDashboard.css';  // Assurez-vous que cette nouvelle feuille de style est bien utilis�e
import { Home, VideoCall, History, SupportAgent, Store, Lock } from '@mui/icons-material'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { getWooCommerceVendorProducts, getVendorNotifications, markNotificationAsRead } from './api';
import Popup from './Popup';

function VendorDashboard() {
  const [activeMenu, setActiveMenu] = useState('Home');
  const [vendorName, setVendorName] = useState('');
  const [vendorSubscription, setVendorSubscription] = useState('Free Plan'); // Par d�faut � 'Free Plan'
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('initial');
  const [selectedEventId, setSelectedEventId] = useState(null); // Ajoutez cet �tat pour stocker l'ID de l'�v�nement s�lectionn�
  const [vendorProducts, setVendorProducts] = useState([]);
  const [showNotificationList, setShowNotificationList] = useState(false); // �tat pour afficher la liste des notifications
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    
    if (token) {
      localStorage.setItem('token', token);
      navigate('/vendor_dashboard', { replace: true });
    } else {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        navigate('/');
      }
    }

    const fetchVendorDetails = (token) => {
      const url = `https://live-discount.com/wp-json/wp/v2/users/me?_=${new Date().getTime()}`;
      
      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch vendor details');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.name) {
          setVendorName(data.name);
          setVendorSubscription(data.subscription || 'Free Plan'); 
        } else {
          setVendorName('Vendeur');
        }
      })
      .catch(error => {
        setVendorName('Vendeur');
      });
    };

    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      fetchVendorDetails(storedToken);
      fetchVendorProducts(storedToken);
      fetchNotifications(storedToken); // Fetch notifications
    }
  }, [location, navigate]);

  const fetchVendorProducts = async (token) => {
    try {
      const products = await getWooCommerceVendorProducts(103); // Utiliser un ID de vendeur simul�
      setVendorProducts(products);
    } catch (error) {
      console.error('Erreur lors de la r�cup�ration des produits du vendeur:', error.message);
    }
  };

  const fetchNotifications = async (token) => {
    try {
      const notifications = await getVendorNotifications(token);
      if (Array.isArray(notifications)) {
        setNotifications(notifications);
      } else {
        console.error('Les notifications r�cup�r�es ne sont pas un tableau:', notifications);
      }
    } catch (error) {
      console.error('Erreur lors de la r�cup�ration des notifications:', error.message);
    }
  };

  const handleResponse = async (eventId, response) => {
    try {
      const token = localStorage.getItem('token');
      // Supposons que vous ayez une fonction pour r�pondre � un �v�nement en direct
      // await respondToLiveEvent(token, eventId, response);
      setNotifications(notifications.filter(notification => notification.id !== eventId));
      if (response === 'accepted') {
        fetchVendorProducts(token);
        setPopupContent('products');
      } else {
        setPopupContent('rejected');
        setTimeout(() => {
          setShowPopup(false);
        }, 2000);
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la r�ponse:', error.message);
    }
  };

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = 'https://live-discount.com';
  };

  const handleProfileHover = () => {
    setShowLogoutMenu(true);
  };

  const handleProfileLeave = () => {
    setShowLogoutMenu(false);
  };

  const handleNotificationClick = (notificationId) => {
    const notification = notifications.find(n => n.id === notificationId);
    if (notification) {
      setSelectedEventId(notification.id); // Stockez l'ID de l'�v�nement s�lectionn�
      setShowPopup(true);
      setPopupContent('initial');
      const token = localStorage.getItem('token');
      markNotificationAsRead(notificationId, token); // Marquer la notification comme lue
      setNotifications(notifications.map(n => n.id === notificationId ? { ...n, read: true } : n));
    }
  };

  const handleNextClick = () => {
    setPopupContent('thankYou');
  };

  const toggleNotificationList = () => {
    setShowNotificationList(!showNotificationList);
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'Home':
        return (
          <div id="bloch3home">
            <div className="cadre-abonnement">
              <h5>{vendorSubscription} </h5>
              <a href="https://live-discount.com" className="upgrade-link"> Upgrade Now</a>
            </div>

            <div className="flex-container">
              <div className="cadrele">
                <div className="block-content">LIVE EVENT</div>
                <button className="join-button">Join a Live Event</button>
              </div>
              <div className="cadreule">
                <div className="block-content">PRIVATE LIVE</div>
                <button className="join-button">Create a Private Live</button>
              </div>
              <div className="cadrenew">
                <div className="block-content">Contenu Renew<br/><br/><br/><br/><br/><br/><br/></div>
              </div>
            </div>

            <div className="flex-container">
              <div className="cadrepl">
                <div className="block-content">Contenu Private Lives</div>
              </div>
              <div className="cadreupl">
                <div className="block-content">Contenu Upcoming Private Lives</div>
              </div>
              <div></div>
              <div></div>
            </div>

            <div className="cadretotal">Contenu Total</div>
          </div>
        );
      case 'Live Events':
        return <div id="bloch3le">Voici vos �v�nements en direct</div>;
      case 'Private Lives':
        return <div id="bloch3pl">Voici vos diffusions priv�es</div>;
      case 'Products':
        return <div id="bloch3products">Gestion de vos produits</div>;
      case 'History':
        return <div id="bloch3history">Historique des �v�nements</div>;
      case 'Support':
        return <div id="bloch3support">Support technique</div>;
      default:
        return <div id="bloch3">Contenu par d�faut</div>;
    }
  };

  return (
    <div className="dashboard-container">
      <div className="bloc1">
        <div className="logo-container">
          <img
            src="https://live-discount.com/wp-content/uploads/2023/10/Logo_live_discount-1024x782.png"
            alt="Logo"
            className="logo"
          />
        </div>
        <ul className="menu1">
          <li className={activeMenu === 'Home' ? 'active' : ''} onClick={() => handleMenuClick('Home')}>
            <Home />
            Home
          </li>
          <li className={activeMenu === 'Live Events' ? 'active' : ''} onClick={() => handleMenuClick('Live Events')}>
            <VideoCall />
            Live Events
          </li>
          <li className={activeMenu === 'Private Lives' ? 'active' : ''} onClick={() => handleMenuClick('Private Lives')}>
            <Lock /> 
            Private Lives
          </li>
          <li className={activeMenu === 'Products' ? 'active' : ''} onClick={() => handleMenuClick('Products')}>
            <Store />
            Products
          </li>
          <li className={activeMenu === 'History' ? 'active' : ''} onClick={() => handleMenuClick('History')}>
            <History />
            History
          </li>
          <li className={activeMenu === 'Support' ? 'active' : ''} onClick={() => handleMenuClick('Support')}>
            <SupportAgent />
            Support
          </li>
        </ul>
        <button
          className="bouton1"
          onClick={() => window.location.href = 'https://live-discount.com/'}
        >
          GO TO DASHBOARD
        </button>
      </div>

      <div className="bloc2">
        <div className="bloch1">
          <span className="admin-name">{vendorName}</span>

          <span className="admin-icon"
            onMouseEnter={handleProfileHover}
            onMouseLeave={handleProfileLeave}
          >
            <img
              src="https://img.icons8.com/?size=100&id=7820&format=png&color=000000"
              alt="Vendor Icon"
            />
            {showLogoutMenu && (
              <div className="admin-menu">
                <a href="#" className="logout-btn" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            )}
          </span>

          <span className="notifications-icon" onClick={toggleNotificationList}>
            <img
              src="https://img.icons8.com/material-outlined/24/alarm.png"
              alt="Notifications"
            />
            {Array.isArray(notifications) && notifications.length > 0 && (
              <span className="notification-count">
                {notifications.filter(n => !n.read).length}
              </span>
            )}
          </span>
        </div>

        {showNotificationList && (
          <div className="notification-list" style={{ height: '300px', overflowY: 'scroll' }}>
            {Array.isArray(notifications) && notifications.map(notification => (
              <div
                key={notification.id}
                className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                onClick={() => handleNotificationClick(notification.id)}
              >
                {notification.message}
                <hr />
              </div>
            ))}
          </div>
        )}

        <div className="bloch2">
          <span className="welcome-text">
            Bienvenue, <strong style={{ color: '#7fd2f3' }}>{vendorName}</strong>
          </span>
        </div>

        <div className="bloch3">
          {renderContent()} {/* Zone de contenu dynamique */}
        </div>
      </div>

      {showPopup && (
        <Popup
          content={popupContent}
          onClose={() => setShowPopup(false)}
          onAccept={() => handleResponse(selectedEventId, 'accepted')}
          onReject={() => handleResponse(selectedEventId, 'rejected')}
          onNext={handleNextClick}
          products={vendorProducts}
        />
      )}
    </div>
  );
}

export default VendorDashboard;